<template>
<div class="card">

    <div class="card-body">
        <div class="row">
            <div class="col-md-4">
                <b-avatar :src="data.vehicle_image_url" size="6rem"></b-avatar>
                <b-avatar v-if="data.deviceActivation.status == 1" class="ml-n1 mt-n4" :variant="`light-info`" size="45">
                    <feather-icon size="21" :icon="'RadioIcon'" />
                </b-avatar>

            </div>
            <div class="col-md-4">
                <p> {{ $t('Surname') }} : {{ data.vehicle }} </p>
                <p> {{ $t('Acronym') }} : {{ data.sigla }} </p>

            </div>
            <div class="col-md-4">
                <p> {{ $t('License Plate') }} : {{ data.license_plate }} </p>
                <p> {{ $t('Frame') }} : {{ data.frame }} </p>
                <p> {{ $t('National car registration') }} : {{ data.national_registry }} </p>
            </div>
            <div class="col-md-12">
                <div class="row ">

                    <div class="col-md-4"> {{ $t('Central') }} : {{ data.center }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Brand') }} : {{ data.vehicle_brand }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Type') }} : {{ data.vehicleType }} </div>

                    <div class="col-md-4"> {{ $t('Unit') }} : {{ data.unit }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Colors') }} : {{ data.vehicle_color }} </div>

                    <div class="col-md-4"> {{ $t('Vehicle Model') }} : {{ data.vehicle_model }} </div>

                    <div class="col-md-12"> {{ $t('Specialty') }} : {{ data.specialty }} </div>
                </div>

            </div>
            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-4"> {{ $t('manufacture Year') }} : {{ data.manufacture_year }} </div>
                    <div class="col-md-4"> {{ $t('model Year') }} : {{ data.model_year }} </div>

                </div>

            </div>
            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-4"> {{ $t('maintenance free') }} : {{ data.maintenance_free == 1? $t('Yes'): $t('No') }} </div>
                    <div class="col-md-8"> {{ $t('checklist free') }} : {{ data.checklist_free == 1? $t('Yes'): $t('No') }} </div>
                    <div class="col-md-12"> {{ $t('description') }} : {{ data.description }} </div>
                </div>

            </div>

        </div>

    </div>
    <div class="card-footer row">
       
        <div class="col-md-2">
            <div class="card text-center shadow-sm mb-4">
                <div class="card-body">
                    <h5 class="card-title">{{ $t('CRLV') }}</h5>
                    <p class="card-text">
                        <b-button v-if="data.doc_vehicle_url" pill variant="outline-primary" :href="data.doc_vehicle_url" download target="_blank">
                         <i class="fa fa-download"></i>
                    </b-button>
                        
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card text-center shadow-sm mb-4">
                <div class="card-body">
                    <h5 class="card-title">{{ $t('Battery level') }}</h5>
                    <div class="card-text">
                        <div class="row">
                            <div class="col-md-6">
                                <p>
                                    
                                    <i class="fa fa-microchip fa-2x mt-n1 " :class="getClassStatus(getBackBattery)"></i>
                                </p>
                                <i :class="getClassStatusIcon(getBackBattery)"></i>
                                <span class="d-block mt-1"><strong> {{ getBackBattery }} V</strong></span>
                            </div>
                            <div class="col-md-6">

                                <p>

                                    <i class="fa fa-car fa-2x mt-n1 " :class="getMainClassStatus(getMainBatteryDec)"></i>
                                </p>
                                <i  :class="getMainClassStatusIcon(getMainBatteryDec)"></i>
                                <span class="d-block mt-1"><strong> {{ getMainBatteryDec }} V</strong></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card text-center shadow-sm mb-4">
                <div class="card-body">
                    <h5 class="card-title">{{ $t('Chip') }}</h5>
                    <p class="card-text">
                         <i class="fa fa-sim-card fa-2x mt-n1 text-primary"></i>
                         <span class="d-block mt-2">{{ data.deviceActivation.telephone ? data.deviceActivation.telephone : $t('Without Chip') }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card text-center shadow-sm mb-4">
                <div class="card-body">
                    <h5 class="card-title">{{ $t('Device') }}</h5>
                    <p class="card-text">
                        <p>
                            <b-button pill :variant="data.deviceActivation.status == 1?'success':'secondary'" @click="$emit('showDeviceActivtions', !modalShow)">
                                <b-badge pill :variant="data.deviceActivation.status == 1?'success':'secondary'"> {{ data.deviceActivation.status == 1? $t('Active'): $t('Inactive')}} </b-badge>
                            </b-button>
                        </p>
                        <b-button v-if="data.deviceActivation.status == 1" 
                            variant="outline-primary" 
                            @click="$emit('showDeviceActivtionsConfig', !modalConfigShow)" 
                            :class="{ 'no-border': isButtonActive }">
                        <i class="fa fa-cogs fa-2x"></i>
                    </b-button>

                        
                    </p>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
const HAS_GPS = require("@/assets/images/resgate/avlIcon.png")

export default {
    props: {
        props: {
            type: Object,
            required: true,
        },
        modalShow: {
            type: Boolean,
            default: false,
        },
        modalConfigShow: {
            type: Boolean,
            default: false,
        },
        backBattery:{
            type: Number,
            default: 41,
        },
        mainBatteryDec:{
            type: Number,
            default: 140,
        }

    },
    data() {
    return {
      isButtonActive: true,
    };
  },
    computed: {
        data() {
            return this.props;
        },
        getSrsGPS() {
            return HAS_GPS;
        },
        getBackBattery() {
            return (this.backBattery / 10).toFixed(1);
        },
        getMainBatteryDec() {
            if (this.mainBatteryDec > 100) {

                return (this.mainBatteryDec / 10).toFixed(1);
            }
            return this.mainBatteryDec;
        }
    },
    methods: {
        formtaDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        },
        getClassStatus(level) {
            const statusClasses = {
                success: level > 3,
                warning: level > 2 && level <= 3.9,
                danger: level <= 1.9,
            };

            return statusClasses.success ? "text-success" : 
                statusClasses.warning ? "text-warning" : "text-danger";
        },
        getMainClassStatus(level) {
            const statusClasses = {
                success: level > 75,
                warning: level > 39 && level <= 74,
                danger: level <= 38,
            };

            return statusClasses.success ? "text-success" : 
                statusClasses.warning ? "text-warning" : "text-danger";
        },
        getClassStatusIcon(level) {
            const statusClasses = {
                success: level > 3,
                warning: level > 2 && level <= 3.9,
                danger: level <= 1.9,
            };

            return statusClasses.success ? "fa fa-battery-full fa-2x mt-n1 text-success" : 
                statusClasses.warning ? "fa fa-battery-half fa-2x mt-n1 text-warning" : "fa fa-battery-quarter fa-2x mt-n1 text-danger";
        },
        getMainClassStatusIcon(level) {
            const statusClasses = {
                success: level > 75,
                warning: level > 39 && level <= 74,
                danger: level <= 38,
            };

            return statusClasses.success ? "fa fa-battery-full fa-2x mt-n1 text-success" : 
                statusClasses.warning ? "fa fa-battery-half fa-2x mt-n1 text-warning" : "fa fa-battery-quarter fa-2x mt-n1 text-danger";
        }
    }
}
</script>

<style scoped>
.no-border {
    border: none !important;
    box-shadow: none !important;
}
</style>

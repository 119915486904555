<template>
    <b-modal v-model="modalShow" size="xl" hide-footer>
      <div class="card">
        <div class="card-body">
          <b-row>
            <div class="col-md-3">
              <div class="card text-center shadow-sm mb-4">
                <div class="card-body">
                  <h5 class="card-title">{{ $t('Device') }}</h5>
                  <p class="card-text">
                    <i class="fa fa-cogs fa-5x mt-n1 text-primary"></i>
                  </p>
                  <p>{{ props.imei }}</p>
                  <p>{{ props.operator }}</p>
                  <p>{{ props.supplier }}</p>
                </div>
              </div>
            </div>
  
            <div class="col-md-6">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-row>
                  <div class="col-md-4" v-for="(field, index) in formFields" :key="index">
                    <b-form-group :id="field.id" :label="$t(field.label)" :label-for="field.id">
                      <b-form-input
                        :id="field.id"
                        type="number"
                        v-model="form[field.model]"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-row>
  
                <div class="block text-right">
                  <b-button type="reset" variant="danger" class="ml-1">
                    {{ $t('Cancel') }}
                    <i class="fa fa-times"></i>
                  </b-button>
                  <b-button type="submit" variant="primary" class="ml-1">
                    {{ $t('Save') }}
                    <i class="fa fa-save"></i>
                  </b-button>
                </div>
              </b-form>
            </div>
          </b-row>
        </div>
      </div>
    </b-modal>
  </template>
  
  <script>
  import axios from "axios";
  
  const dataInitial = {
    imei: '',
    operator: '',
    supplier: '',
    GPRSTimeout: 0,
    MaxTimeWithNoMsg: 0,
    MsgLifeTime: 0,
    NormalTracking: 0,
    PanicExpiration: 0,
    PanicTracking: 0,
    SendTime: 0,
    SleepTime: 0,
    WakeUpSendTime: 0,
  };
  
  export default {
    props: {
      props: {
        type: Object,
        required: true,
      },
      modalShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: { ...dataInitial },
        show: true,
        formFields: [
          { id: 'GPRSTimeout-2', label: 'GPRSTimeout', model: 'GPRSTimeout' },
          { id: 'MaxTimeWithNoMsg-2', label: 'MaxTimeWithNoMsg', model: 'MaxTimeWithNoMsg' },
          { id: 'MsgLifeTime-2', label: 'MsgLifeTime', model: 'MsgLifeTime' },
          { id: 'NormalTracking-2', label: 'NormalTracking', model: 'NormalTracking' },
          { id: 'PanicExpiration-2', label: 'PanicExpiration', model: 'PanicExpiration' },
          { id: 'PanicTracking-2', label: 'PanicTracking', model: 'PanicTracking' },
          { id: 'SendTime-2', label: 'SendTime', model: 'SendTime' },
          { id: 'SleepTime-2', label: 'SleepTime', model: 'SleepTime' },
          { id: 'WakeUpSendTime-2', label: 'WakeUpSendTime', model: 'WakeUpSendTime' },
        ],
      };
    },
    methods: {
      closeModal() {
        this.$emit("hideDeviceActivtionsConfig", false);
      },
      onSubmit(event) {
        event.preventDefault();
        if (JSON.stringify(this.form) === JSON.stringify(dataInitial)) {
          // form is empty
          return;
        }
        this.save();
      },
      save() {
        let api = this.$store.state.api + "configdevices";
        if (this.form.id) {
          this.form._method = "PUT";
          api += "/" + this.form.id;
        }
  
        this.form.imei = this.props.imei;
        this.form.operator = this.props.operator;
        this.form.supplier = this.props.supplier;
  
        axios
          .post(api, this.form)
          .then((response) => {
            this.$message(
              this.$t("Success"),
              this.$t("Information saved successfully"),
              "success"
            );
          })
          .catch((error) => {
            this.$error(error);
          });
        this.closeModal();
      },
      onReset(event) {
        event.preventDefault();
        this.form = { ...dataInitial };
  
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  };
  </script>
  